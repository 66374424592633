
import Header from '@/components/header/index.vue'
import {config} from "@/utils"
export default {
    name: 'about',
    components:{
        Header,
        
    },
    mounted () {
       
    },
    created(){
       this.init()
       

    },
    methods: {
        init(){
            if(this.$route.query.type !=undefined){
                    this.zNum=this.$route.query.type
                }

        },
        
        changeZ(v) {
            this.zNum = v
        },
    },

    data(){
        return{
            zNum:1,

        }
        
    }
    
}